<script setup lang="ts">
import {h, ref, onMounted} from 'vue';
import {ConfigProvider, Spin, theme} from 'ant-design-vue';
import {LoadingOutlined} from '@ant-design/icons-vue';
import antdEnUS from 'ant-design-vue/es/locale/en_US';
import antdZhCN from 'ant-design-vue/es/locale/zh_CN';
import {useLocale} from "./hooks/useLocale.ts";
import {GlobalLocale} from "./constants/locale.ts";
import {locale as dayjsLocale} from 'dayjs';
import 'dayjs/locale/zh-cn';
import {setI18nLanguage} from "./i18n.ts";

const {onLocaleChange} = useLocale();
const antdLocale = ref(antdEnUS);

onLocaleChange(locale => {
  antdLocale.value = locale === GlobalLocale.En ? antdEnUS : antdZhCN
  setI18nLanguage(locale)
  dayjsLocale(locale)
})

if (__DEV__) {
  const { useToken } = theme;
  const { token } = useToken();
  console.log('查看所有token', token.value)
}

Spin.setDefaultIndicator({
  indicator: h(LoadingOutlined, {
    spin: true,
  }),
});

</script>
<template>
  <!--  变量：https://antdv.com/docs/vue/customize-theme-cn#rice -->
  <ConfigProvider
    :theme="{
      token: {
        colorPrimary: '#F70800',
        colorPrimaryBgHover: '#E65043',
        colorPrimaryActive: '#BD1411',
        borderRadius: 3,
        colorSplit: 'rgba(0, 0, 0, 0.06)',
        colorLink: '#4666d7',
        colorSuccess: '#18B534',
        colorInfo: '#1C4AD4',
        colorText: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'PingFang SC, \'思源黑体 CN\', Mircrosoft YaHei, Arial, \'sans-serif\'',
        lineHeight: 1.5
      },
      components: {
        Form: {
          colorTextHeading: 'rgba(0, 0, 0, 0.65)',
          marginLG: 20,
          lineHeight: 1.5
        },
        Table: {
          paddingContentVerticalLG: 12,
          padding: 12,
          colorTextHeading: 'rgba(0, 0, 0, 0.65)',
          colorText: 'rgba(0, 0, 0, 0.65)',
        },
        Divider: {
          marginLG: 16
        },
        Alert: {
          colorText: 'rgba(0, 0, 0, 0.65)'
        },
        Input: {  
          colorPrimaryHover: 'rgba(0, 0, 0, 0.65)',
          colorPrimaryActive: 'rgba(0, 0, 0, 0.65)',
          controlOutline: 'none',
        },
        InputNumber: {
          colorPrimaryHover: 'rgba(0, 0, 0, 0.65)',
          colorPrimaryActive: 'rgba(0, 0, 0, 0.65)',
          controlOutline: 'none',
        },
        Select: {
          colorPrimaryHover: 'rgba(0, 0, 0, 0.65)',
          colorPrimaryActive: 'rgba(0, 0, 0, 0.65)',
          controlItemBgActive: 'rgba(0, 0, 0, 0.05)',
          controlOutline: 'none',
        },
        DatePicker: {
          colorPrimaryHover: 'rgba(0, 0, 0, 0.65)',
          colorPrimaryActive: 'rgba(0, 0, 0, 0.65)',
          controlOutline: 'none',
        },
        Upload: {
          colorPrimaryHover: 'rgba(0, 0, 0, 0.65)',
          colorPrimaryActive: 'rgba(0, 0, 0, 0.65)',
          controlOutline: 'none',
        },
        Tabs: {
          fontSize: 16
        },
        Drawer: {
          fontSizeLG: 18
        },
        Modal: {
          colorBgMask: 'rgba(0,0,0,0.65)'
        }
      }
    }"
    :locale="antdLocale"
  >
    <router-view />
  </ConfigProvider>
</template>